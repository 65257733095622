:root {
  --color-primary: #f5f200;
  --color-primary-shade: #c1b900;
  --color-secondary: #3e9741;
  --color-secondary-tint: #27ae60;
  --color-secondary-shade: #007000;
  --color-secondary-light: #d2f3d3;
  --color-success: #54cc59;
  --color-tertiary: #0e5dac;
  --color-tertiary-tint: #87b9ec;
  --color-tertiary-light: #deecfa;
  --color-danger: #fd351a;
  --color-danger-tint: #ffe5dc;
  --color-warning: #e76c22;
  --color-warning-tint: #fce6a3;
  --color-highlight: #deecfa;
  --color-highlight-shade: #b3d3f3;
  --color-highlight-tint: #f2f8ff;
  --color-highlight-tint-2: #f7fafe;
  --color-border-light: #eaeaea;
  --color-border: #939393;
  --color-light: #cfcfcf;
  --color-light-shade: #5d5d5d;
  --color-dark: 51 51 51;
  --color-background: #fff;
  --color-gray: #f5f5f5;
}

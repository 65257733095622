@import './variables.scss';

@font-face {
  font-family: 'Make It Sans';
  src: url('../assets/fonts/Make It Sans/Make It Sans Web Regular.woff2')
      format('woff2'),
    url('../assets/fonts/Make It Sans/Make It Sans Web Regular.woff')
      format('woff'),
    url('../assets/fonts/Make It Sans/Make It Sans Web Regular.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Define bold font weight */
@font-face {
  font-family: 'Make It Sans';
  src: url('../assets/fonts/Make It Sans/Make It Sans Web Bold.woff2')
      format('woff2'),
    url('../assets/fonts/Make It Sans/Make It Sans Web Bold.woff')
      format('woff'),
    url('../assets/fonts/Make It Sans/Make It Sans Web Bold.ttf')
      format('truetype');
  font-weight: 700;
  font-style: normal;
}

html,
body {
  font-family: 'Make It Sans', -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(var(--color-dark));
  background: var(--color-background);
}

::-moz-selection {
  /* Code for Firefox */
  color: rgb(var(--color-dark));
  background: var(--color-tertiary-tint);
}

::selection {
  color: rgb(var(--color-dark));
  background: var(--color-tertiary-tint);
}

#__filestack-picker {
  position: fixed;
  z-index: 9999999;
}

.html-md {
  a {
    @apply underline;
  }
}

.markdown {
  ul,
  ol {
    @apply -mt-[18px] leading-4;
  }

  ul li {
    @apply ml-8 list-disc leading-6;
  }
}

.ant-carousel {
  overflow: hidden;
}

.ant-carousel .slick-arrow {
  opacity: 1 !important;
  width: 24px;
  height: 24px;
}

.ant-carousel .slick-list {
  border-radius: 4px;
  border: 1px solid #ddd;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 45px;
    z-index: 1;
  }

  &::before {
    background: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.28));
  }

  &::after {
    right: 0;
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.28));
  }
}

.ant-carousel .slick-disabled {
  opacity: 0 !important;
}
